import { DateTime,Settings  } from "luxon";

export default ({ app }, inject) => {
  Settings.defaultZoneName = "Europe/Athens";
  Settings.defaultLocale = "el-GR";
    inject("luxon", (time,options) => {
      let dt = DateTime.fromMillis(time);
      if(options.format){
        return dt.toFormat(options.format)
      }
      return dt;
    });
  };